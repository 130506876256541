import React, { FC, useEffect, useState } from 'react';
// import AnyReactComponent from 'components/AnyReactComponent/AnyReactComponent';
// import GoogleMapReact, { contextType } from 'google-map-react';
// import { DEMO_STAY_LISTINGS } from 'data/listings';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Checkbox from 'shared/Checkbox/Checkbox';
// import Pagination from 'shared/Pagination/Pagination';
import TabFilters from './TabFilters';
import Heading2 from 'components/Heading/Heading2';
import PropertyCardH from 'components/PropertyCardH/PropertyCardH';
import { getAllProperties } from 'data/DataController';
import { PropertiesContext } from 'context/PropertiesContext';
import appConfig from 'config/appConfig';
// import { Link } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import useWindowSize from 'hooks/useWindowResize';

import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  useMapEvents,
  Popup,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  //const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

  const PropertiesContextState = React.useContext(PropertiesContext);
  const searchResults = PropertiesContextState.results;

  const windowSize = useWindowSize();
  const mobileMinWidthBreakpoint = 768;

  useEffect(() => {
    // console.log(PropertiesContextState);
    // console.log(searchResults);
    // console.log(PropertiesContextState.results.length);
    // console.log(searchResults == PropertiesContextState.results);
    // setShowLoadMoreButton(false);
    // setShowLoadMoreButton(true);

    // console.log(PropertiesContextState.searchQuery.page);
    // console.log(PropertiesContextState.results.length);

    if (
      PropertiesContextState.searchQuery.page == 1 &&
      PropertiesContextState.results.length % appConfig.LIMIT_ITEMS == 0
    ) {
      setShowLoadMoreButton(true);
    }

    if (PropertiesContextState.results.length < appConfig.LIMIT_ITEMS) {
      setShowLoadMoreButton(false);
    }
  }, [PropertiesContextState.results]);

  // console.log('Search results: ');
  // console.log(searchResults);

  const customIcon = new L.Icon({
    //iconUrl: '../../images/marker-icon.png',
    //iconUrl: require('../../../node_modules//leaflet/dist/images/marker-icon.png'),
    //iconUrl: require('../../images/money-bag.png'),
    iconUrl: require('../../images/plattenmarker.png'),
    iconSize: new L.Point(35, 35),
    //iconUrl: require('../../images/favicon.png'),
    //iconUrl: require('../../images/marker-icon.png'),

    // opacity: 0.5,
    //shadowSize: new L.Point(68, 95),
    // iconAnchor: new L.Point(16, 16),
    // popupAnchor: new L.Point(0, -18),
  });

  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
      // dblclick(e) {
      //   console.log('doble click: ' + e.latlng);
      // },
      // click(e: any) {
      //   console.log('single click: ' + e.latlng);
      //   //setPosition(e.latlng);
      //   map.flyTo({ lat: 48.29963964777107, lng: 5.657958984375 });
      //   console.log('leaflet alt lng-------------------->');
      //   // console.log({ lat: 48.29963964777107, lng: 5.657958984375 });
      // },
      // dragend(e) {
      //   console.log('drag end: ');
      //   // console.log(e.target);
      //   // console.log(e.distance);
      //   // console.log(e.sourceTarget);
      //   // console.log(e.popup);
      //   // console.log(e.type);
      // },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Tooltip>You are here</Tooltip>
      </Marker>
    );
  }

  // function setMapLocation(lat: number, lng: number) {
  //   const map = useMapEvents({
  //     //setPosition(e.latlng);
  //     // map.panTo(LatLng
  //     preclick(e: any) {
  //       map.flyTo({ lat: 48.29963964777107, lng: 5.657958984375 });
  //       map.panTo({ lat: 48.29963964777107, lng: 5.657958984375 });
  //     },
  //   });
  // }

  function setMapLocation(lat: number, lng: number) {
    /* @ts-ignore */
    // mapRef?.setView({ lat, lng }, 12);
    /* @ts-ignore */
    mapRef?.flyTo({ lat, lng }, 13);
    // mapRef?.flyTo({ lat: 46.908349, lng: 18.03872 });
  }

  // useEffect(() => {
  //   console.log(
  //     '================================ USE MEMO =========================='
  //   );
  //   /* @ts-ignore */
  //   // mapRef?.setView({ lat: 48.29963964777107, lng: 5.657958984375 }, 15);
  //   /* @ts-ignore */
  //   mapRef?.flyTo({ lat: 46.908349, lng: 18.03872 });
  // }, [mapRef]);

  // PropertiesContextState.setContext({
  //   any: 'any',
  // });

  // useEffect(() => {
  //   PropertiesContextState.setContext({
  //     any: 'any',
  //   });
  // }, []);

  // PropertiesContextState.results;

  // useEffect( () => {
  //   const map = useMapEvents({
  //       map.flyTo();
  //   });
  // } , [])

  return (
    <div>
      <MapContainer
        className='markercluster-map'
        // center={[50.4501, 30.5234]}
        // zoom={12}
        // maxZoom={20}

        center={[47.1984955, 19.99890966]}
        // zoom={13}
        // scrollWheelZoom={true}
        zoom={8}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        //animate={true}
        easeLinearity={0.35}
        /* @ts-ignore */
        ref={setMapRef}
        //preferCanvas={true}
        // onClick={(e: any) => {
        //   console.log(e);
        // }}
      >
        <LocationMarker />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
        />
        {/* @ts-ignore */}
        <MarkerClusterGroup
        // onClick={(e: any) => {
        //   console.log(e.latlng);
        // }}
        //spiderfyOnMaxZoom={false}
        >
          {searchResults.map((item: any) => (
            <Marker
              position={[item.latitude, item.longitude]}
              key={item.id}
              icon={customIcon}
              // eventHandlers={{
              //   click: (e: any) => {
              //     console.log(e.latlng);
              //   },
              // }}
            >
              {/* <Tooltip></Tooltip> */}

              <div
              // onClick={() => {
              //   /* @ts-ignore */
              //   mapRef?.closePopup();
              // }}
              >
                <Popup
                  maxWidth={250}
                  closeButton={false}
                  // closeOnClick={true}
                  // closeOnEscapeKey={true}
                  // autoClose={true}
                >
                  <div className={'w-64 h-full rounded-3xl '}>
                    {/* <Link to={`${appConfig.URL}property/${item.id}`}> */}
                    <a href={`${appConfig.URL}property/${item.id}`}>
                      <img
                        src={item.image}
                        width='250'
                        height='250'
                        alt='no img'
                        // style={{ borderRadius: 10 }}
                        className={'rounded-lg'}
                      />
                      {/* </a> */}

                      <div className='flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2 mt-2'>
                        <div className='text-primary-500 '>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-5 w-5'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                          >
                            <path
                              fillRule='evenodd'
                              d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z'
                              clipRule='evenodd'
                            />
                          </svg>
                        </div>
                        <span className=''>{item.city}</span>
                      </div>
                      <div className='mt-2 text-base font-semibold'>
                        {item.title}
                      </div>
                      <div className='w-100 mt-2 border-b border-neutral-200 dark:border-neutral-800'></div>
                      <div className='flex mt-3 justify-between items-center'>
                        <span className='text-base font-semibold'>
                          {item.price}
                        </span>
                        <a href={`${appConfig.URL}property/${item.id}`}>
                          <span className='text-base font-semibold text-[#017a4e]'>
                            {'Bővebben'}
                          </span>
                        </a>
                      </div>
                      {/* <div
                            className='absolute top-0 right-0 m-2 text-base cursor-pointer outline outline-offset-2 outline-cyan-500 rounded'
                            onClick={() => {
                            
                              mapRef?.closePopup();
                            }}
                          >
                            X
                          </div> */}
                      {/* </Link> */}
                    </a>

                    <div
                      className='absolute top-2 right-2  cursor-pointer'
                      onClick={() => {
                        /* @ts-ignore */
                        mapRef?.closePopup();
                      }}
                    >
                      {/* <span>Close</span> */}
                      <span className='w-5 h-5 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-4 w-4'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                        >
                          <path
                            fill-rule='evenodd'
                            d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </Popup>
              </div>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      {/* @ts-ignore*/}
      <div className='container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32'>
        {/* CARDSSSS */}
        {/* {console.log('Rendering property cards: ')}
        {console.log(PropertiesContextState.results)} */}
        <div className=''>
          {/* <TabFilters /> */}
          {/* <div className='block sm:hidden'>
            <div className='px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center'>
              <div className='lg:hidden flex-[3] max-w-lg !mx-auto md:px-3'>
                <button
                   onClick={openModal}
                  className='flex i-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg'
                >
          
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
                    />
                  </svg>

                  <div className='ml-3 flex-1 text-left overflow-hidden'>
                    <span className='block font-medium text-sm'>Keresés</span>
                    <span className='block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 line-clamp-1'>
            
                      Multihome Ingatlanok
                    </span>
                  </div>

                  <span className='absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300'>
                    <svg
                      viewBox='0 0 16 16'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                      className='block w-4 h-4'
                      fill='currentColor'
                    >
                      <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div> */}
          <Heading2
            heading='Keresési találatok'
            subHeading={
              <span className='block text-neutral-500 dark:text-neutral-400 mt-3'>
                {searchResults.length + ' ingatlan'}
                {/* <span className='mx-2'>·</span>
                Aug 12 - 18
                <span className='mx-2'>·</span>2 Guests */}
              </span>
            }
            className={'mb-0 sm:mb-0 lg:mb-0'}
          />

          <div className='mb-8 lg:mb-11'>
            <TabFilters
            // isMobile={windowSize.width <= mobileMinWidthBreakpoint}
            />
          </div>

          {/* {searchResults &&
            searchResults.length >= 0 &&
            /* @ts-ignore 
            searchResults.map((item) => console.log(item))} */}

          <div className='grid grid-cols-2 gap-8'>
            {searchResults &&
              searchResults.length >= 0 &&
              /* @ts-ignore */
              searchResults.map(
                /* @ts-ignore */
                (item) => (
                  //console.log('Search result item in mapgrid: '),
                  //console.log(item),
                  // <div
                  //   key={item.id}
                  //   onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                  //   onMouseLeave={() => setCurrentHoverID((_) => -1)}
                  // >
                  /* {console.log('=====> Property CradH data:')}
                    {console.log(searchResults)} */
                  <PropertyCardH
                    data={item}
                    key={item.id}
                    onGoToLocation={(lat: number, lng: number) => {
                      // console.log('location: ' + lat, lng);
                      setMapLocation(lat, lng);
                    }}
                    showMarker={true}
                    showCity={true}
                    gapNumberMobileArray={[6, 2]}
                    gridColNumber={2}
                  />

                  // </div>
                )
              )}
          </div>
          <div className='flex mt-16 justify-center items-center'>
            {/* <ButtonPrimary loading>Load more </ButtonPrimary> */}
            {showLoadMoreButton && (
              <ButtonPrimary
                // @ts-ignore
                onClick={() => {
                  //console.log('Load more pressed');
                  getAllProperties({
                    ...PropertiesContextState.searchQuery,
                    page: PropertiesContextState.searchQuery.page + 1,
                  }).then((response) => {
                    PropertiesContextState.setContext({
                      results: PropertiesContextState.results.concat(response),
                      searchQuery: {
                        ...PropertiesContextState.searchQuery,
                        page: PropertiesContextState.searchQuery.page + 1,
                      },
                      activePropertyTypeId:
                        PropertiesContextState.activePropertyTypeId,
                    });
                    if (response.length < appConfig.LIMIT_ITEMS) {
                      setShowLoadMoreButton(false);
                    }
                  });
                }}
              >
                Több mutatása
              </ButtonPrimary>
            )}
          </div>
          {/* <div className='flex mt-16 justify-center items-center'>
            <Pagination />
          </div> */}
        </div>

        {/* <div
          className='flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer'
          onClick={() => setShowFullMapFixed(true)}
        >
          <i className='text-lg las la-map'></i>
          <span>Show map</span>
        </div> */}

        {/* MAP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? 'fixed inset-0 z-50' : 'hidden'
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className='bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10'
            />
          )}

          {/* <div className='fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden'>
            <div className='absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white shadow-xl z-10 rounded-2xl min-w-max'>
              <Checkbox
                className='text-xs xl:text-sm text-neutral-800'
                name='xx'
                label='Search as I move the map'
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
