// @ts-nocheck
import React, { useState } from 'react';
import { FC } from 'react';
import { useEffect } from 'react';
import ClearDataButton from './ClearDataButton';
import { useRef } from 'react';
//import MultipleSelect from 'components/HeroSearchMobile/MultipleSelectCheckbox';
import MultipleSelect from './MultipleSelectDesktop';
import { getCounties, getDistricts } from 'data/DataController';
//import Checkbox from 'shared/Checkbox/Checkbox';
import Checkbox from './CheckBoxSimple';

export interface LocationInputProps {
  defaultValue: string;
  onChangeSelectedCity?: (allCity: Array<object>) => void;
  onChangeSelectedDistrict?: (
    allDistrict: Array<object>,
    selectedValues: Array<number>
  ) => void;
  onChangeSelectedCounty?: (
    allCounty: Array<object>,
    selectedValues: Array<number>
  ) => void;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  suggestions?: any[];
  defaultCities?: any[];
  defaultDistricts?: any[];
  defaultCounties?: any[];
}

const LocationInput: FC<LocationInputProps> = ({
  defaultValue,
  defaultCities,
  defaultDistricts,
  defaultCounties,
  autoFocus = false,
  onChangeSelectedCity,
  onChangeSelectedDistrict,
  onChangeSelectedCounty,
  onInputDone,
  placeHolder = 'Város',
  desc = 'Merre keresnél?',
  className = 'nc-flex-1.5',
  suggestions,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const innerContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const citySuggestionRef = useRef<HTMLSpanElement>([]);

  const [value, setValue] = useState(defaultValue);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [showCitySuggestions, setShowCitySuggestions] = useState(false);

  const [filteredSuggestions, setFilteredSuggestions] = useState(['']);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');
  const suggestionsState = useState(suggestions);

  const [citySuggestions, setCitySuggestions] = useState(
    suggestions.map(function (item: any) {
      return item['cityName'];
    })
  );

  const [isCountySearch, setIsCountySearch] = useState(false);

  // const [selectedCitiesText, setSelectedCitiesText] = useState([]);
  // const [selectedCityIDs, setSelectedCityIDs] = useState([]);
  const [selectedCities, setSelectedCities] = useState(defaultCities || []);

  const defaultCounty = [];
  const [allCounty, setAllCounty] = useState(defaultCounties || []);
  const [selectedCounty, setSelectedCounty] = useState(defaultCounty);

  const defaultDistrict = [];
  const [allDistrict, setAllDistrict] = useState(defaultDistricts || []);
  const [selectedDistrict, setSelectedDistrict] =
    // @ts-ignore
    useState(defaultDistrict);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const userInput = e.currentTarget.value;

    if (suggestions) {
      let citySuggestions = suggestions.map(function (item: any) {
        return item['cityName'];
      });
      // Filter our suggestions that don't contain the user's input

      const unLinked = citySuggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      setInput(e.currentTarget.value);
      //setValue(e.currentTarget.value);
      // onChangeParent(e.currentTarget.value);
      setFilteredSuggestions(unLinked);
      setActiveSuggestionIndex(0);
      setShowSuggestions(true);

      if (e.currentTarget.value !== '') {
        setShowCitySuggestions(true);
      } else {
        setShowCitySuggestions(false);
      }
    }
  };

  // useEffect(() => {
  //   console.log('Selected District: ');
  //   console.log(selectedDistrict);
  // }, [selectedDistrict]);

  // useEffect(() => {
  //   console.log('////////////////////////////////////////////');
  //   console.log('All District Changed State: ');
  //   console.log(allDistrict);
  //   console.log('////////////////////////////////////////////');
  // }, [allDistrict]);

  useEffect(() => {
    // const selectedCityIDs = selectedCities.map(function (item: any) {
    //   return item['cityId'];
    // });
    // onChangeSelectedCity(selectedCityIDs);

    onChangeSelectedCity(selectedCities);
  }, [selectedCities]);

  useEffect(() => {
    onChangeSelectedDistrict(allDistrict, selectedDistrict);
  }, [selectedDistrict]);

  useEffect(() => {
    onChangeSelectedCounty(allCounty, selectedCounty);
  }, [selectedCounty]);

  // useEffect(() => {
  //   if (filteredSuggestions && suggestions && filteredSuggestions[0]) {
  //     console.log(suggestions);
  //     console.log(filteredSuggestions[0]);
  //     console.log(
  //       suggestions.find(
  //         (suggestion) => suggestion.cityName === filteredSuggestions[0]
  //       ).cityId
  //     );
  //   }
  // }, [filteredSuggestions]);

  // useEffect(() => {
  //   if (!defaultValue) return;
  //   setTypeOfProperty(defaultValue);
  // }, defaultValue);

  //activePropertyTypes.forEach((element) => types[0]['name']);

  // const onClick = (e: React.FormEvent<HTMLInputElement>) => {
  //   setFilteredSuggestions([]);
  //   setInput(e.currentTarget.innerText);
  //   setActiveSuggestionIndex(0);
  //   setShowSuggestions(false);
  // };

  // const onKeyDown = (e: React.KeyboardEvent<object>) => {
  //   // User pressed the enter key
  //   if (e.key === 'Enter') {
  //     setInput(filteredSuggestions[activeSuggestionIndex]);
  //     setActiveSuggestionIndex(0);
  //     setShowSuggestions(false);
  //   }
  //   // User pressed the up arrow
  //   else if (e.key === 'ArrowRight') {
  //     if (activeSuggestionIndex === 0) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex - 1);
  //   }
  //   // User pressed the down arrow
  //   else if (e.key === 'ArrowDown') {
  //     if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex + 1);
  //   }
  // };

  useEffect(() => {
    setCitySuggestions(
      suggestions.map(function (item: any) {
        return item['cityName'];
      })
    );
  }, [suggestions]);

  // useEffect(() => {
  //   setInput(defaultValue);
  // }, [defaultValue]);

  // useEffect(() => {
  //   setShowPopover(autoFocus);
  // }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv);
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv);
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv);
    };
  }, [showPopover]);

  // useEffect(() => {
  //   onChangeParent && onChangeParent(value);
  // }, [value, input]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    setSelectedCities(defaultCities);
  }, [defaultCities]);

  useEffect(() => {
    if (!defaultDistricts) return;
    setAllDistrict(defaultDistricts);
    if (defaultDistricts.length === 0) return;

    const onlyCheckedValues = defaultDistricts.reduce(
      // @ts-ignore
      (previousValue, currentValue) => {
        //   console.log(currentValue.checked);
        if (currentValue.checked === true) {
          return [...previousValue, currentValue.id];
        }
        return previousValue;
      },
      []
    );

    setSelectedDistrict(onlyCheckedValues);
  }, [defaultDistricts]);

  useEffect(() => {
    if (!defaultCounties) return;
    setAllCounty(defaultCounties);
    if (defaultCounties.length === 0) return;

    const onlyCheckedValues = defaultCounties.reduce(
      // @ts-ignore
      (previousValue, currentValue) => {
        //   console.log(currentValue.checked);
        if (currentValue.checked === true) {
          return [...previousValue, currentValue.id];
        }
        return previousValue;
      },
      []
    );

    setSelectedCounty(onlyCheckedValues);
  }, [defaultCounties]);

  useEffect(() => {
    onChangeSelectedCity(selectedCities);
    console.log('---> LocatioInputDesktop citys from prop');
    console.log(selectedCities);
    // console.log(selectedCityIDs);
  }, [selectedCities]);

  useEffect(() => {
    console.log('Show city suggestions state changed');
  }, [showCitySuggestions]);

  useEffect(() => {
    console.log('Show popover state changed');
  }, [showPopover]);

  useEffect(() => {
    console.log('Show suggestions state changed');
  }, [showSuggestions]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    console.log('=========================================>>>>');
    console.log('Click outside runs');
    //setShowPopover(false);
    // if (!containerRef.current) return;
    // if (!inputRef.current) return;
    // if (showPopover) return;

    console.log('Mouse event target: ');
    console.log(event.target);
    console.log(event.target.localName);
    console.log(event.currentTarget);
    console.log(event);
    console.log('==================');
    console.log('Check classes:');
    console.log(event.target);
    let isCitySuggestionClick = true;
    try {
      isCitySuggestionClick = event.target.className.includes(
        'suggestionIdentifier'
      );
    } catch {
      isCitySuggestionClick =
        event.target.localName === 'svg' ||
        event.target.localName === 'span' ||
        event.target.localName === 'path';

      console.log(
        'Is city suggestion click runs in catch and set to: ' +
          isCitySuggestionClick
      );
    }

    console.log('iscitysuggestio click: ' + isCitySuggestionClick);
    //console.log(isCitySuggestionClick);
    //console.log(event.target.classList);
    // setShowCitySuggestions(false);

    // if (containerRef.current) {
    //   console.log('1');
    // }
    // if (inputRef.current) {
    //   console.log('2');
    // }

    // console.log('length: ');
    // console.log(citySuggestionRef.current.length);

    if (containerRef.current.contains(event.target as Node)) {
      console.log('3');
      return;
    }

    if (
      !containerRef.current.contains(event.target as Node) &&
      !isCitySuggestionClick
    ) {
      console.log('4');
      setShowPopover(false);
      return;
    }

    if (innerContainerRef.current.contains(event.target as Node)) {
      console.log('5!');
      return;
    }

    // if (!inputRef.current.contains(event.target as Node)) {
    //   console.log('5');
    //   setCitySuggestions(false);
    // }
    if (showPopover) {
      console.log('show popover is TRUE');
    }
    // if (showPopover || containerRef.current.contains(event.target as Node)) {
    //   return;
    // }
    // CLICK OUT_SIDE
    //console.log('Event click outside div');
    //if()
    //setShowPopover(false);
  };

  const handleSelectLocation = (item: string) => {
    console.log('Item is:');
    console.log(item);
    setInput('');
    setValue('');
    onInputDone && onInputDone(item);
    //setShowPopover(false);
    //onChangeParent(item);
    setCitySuggestions((citySuggestions) => [citySuggestions, item]);
    setShowCitySuggestions(false);

    if (suggestions && item != '') {
      // console.log(
      //   suggestions.find((suggestion) => suggestion.cityName === item).cityId
      // );
      let cityId = suggestions.find(
        (suggestion) => suggestion.cityName === item
      ).cityId;

      // setSelectedCityIDs((prevSelectedCityIDs) => [
      //   ...prevSelectedCityIDs,
      //   cityId,
      // ]);

      // setSelectedCitiesText((prevSelectedCityText) => [
      //   ...prevSelectedCityText,
      //   item,
      // ]);

      let locationAlreadySelected = selectedCities.filter(
        (selectedLocation) => selectedLocation['cityId'] === cityId
      );

      let isLocationAlreadySelected = locationAlreadySelected.length !== 0;

      if (!isLocationAlreadySelected) {
        setSelectedCities((prevSelectedCities) => [
          ...prevSelectedCities,
          { cityId: cityId, cityName: item },
        ]);
      }
    }
  };

  // const Checkbox2 = ({ name, label, value, onChange }) => {
  //   return (
  //     <div className='flex text-sm sm:text-base'>
  //       <input
  //         id={name}
  //         name={name}
  //         type='checkbox'
  //         checked={value}
  //         onChange={(e) => onChange && onChange(e.target.checked)}
  //         className='focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500'
  //       />
  //       {label && (
  //         <label
  //           htmlFor={name}
  //           className='ml-3.5 flex flex-col flex-1 justify-center'
  //         >
  //           <span className='text-neutral-900 dark:text-neutral-100'>
  //             {label}
  //           </span>
  //         </label>
  //       )}
  //     </div>
  //   );
  // };

  const renderSearchValue = () => {
    return (
      <>
        {filteredSuggestions.map((item, id) => (
          <span
            onClick={(e) => {
              e.preventDefault();
              handleSelectLocation(item);
            }}
            // ref={(el) => (citySuggestionRef.current[item] = el)}
            key={item}
            className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 rounded-xl cursor-pointer suggestionIdentifier'
          >
            <span className='block text-neutral-400'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-4 w-4 sm:h-6 sm:w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                />
              </svg>
            </span>
            <span className='block font-medium text-neutral-700 dark:text-neutral-200 suggestionIdentifier'>
              {item}
            </span>
          </span>
        ))}
      </>
    );
  };

  // const onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
  //   const userInput = e.currentTarget.value;

  //   if (suggestions) {
  //     let citySuggestions = suggestions.map(function (item: any) {
  //       return item['cityName'];
  //     });

  //     // Filter our suggestions that don't contain the user's input
  //     const unLinked = citySuggestions.filter(
  //       (suggestion) =>
  //         suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
  //     );

  //     setInput(e.currentTarget.value);
  //     setValue(e.currentTarget.value);
  //     setFilteredSuggestions(unLinked);
  //   }
  // };

  const renderSearchByLocation = () => {
    return (
      <>
        <div
          className='flex-grow overflow-y-auto bg-neutral-100'
          ref={innerContainerRef}
        >
          {/* px-10 */}
          <div className='px-1 sm:px-4 pb-8 space-y-5 divide-neutral-200 dark:divide-neutral-800'>
            <div className={`${className}`}>
              <div className='p-5'>
                <div className='relative mt-5'>
                  {/* <input
                    className={`block w-full bg-white border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
                    placeholder={'Keresés városok szerint'}
                    value={value}
                    // onChange={(e) => setValue(e.currentTarget.value)}
                    onChange={(e) => onChangeInput(e)}
                    ref={inputRef}
                  /> */}
                  <input
                    className={`block w-full bg-white border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
                    placeholder={placeHolder}
                    // value={value}
                    autoFocus={showPopover}
                    // onChange={(e) => setValue(e.currentTarget.value)}
                    ref={inputRef}
                    onChange={onChange}
                    // onKeyDown={onKeyDown}
                    value={input}
                  />
                  <span className='absolute right-2.5 top-1/2 -translate-y-1/2'>
                    {/* <MagnifyingGlassIcon className='w-5 h-5 text-neutral-700 dark:text-neutral-400' /> */}
                  </span>
                </div>
                <div>
                  {showCitySuggestions && (
                    <div className='mt-1 absolute w-2/3 rounded-xl border-solid border-2 border-[#017A4E] bg-white z-50 overflow-y-auto max-h-66'>
                      {renderSearchValue()}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='flex flex-wrap gap-4'>
              {/* {selectedCitiesText.map((item) =>
                renderSelectedLocationsWithClose(item)
              )} */}
              {selectedCities.length !== 0 &&
                selectedCities.map((item) =>
                  renderSelectedLocationsWithClose(item.cityId, item.cityName)
                )}
              {allDistrict.length !== 0 &&
                allDistrict.map((item) => {
                  if (item.checked !== true) {
                    return;
                  }
                  return renderSelectedDistrictsWithClose(item.id, item.title);
                })}
              {allCounty.length !== 0 &&
                allCounty.map((item) => {
                  if (item.checked !== true) {
                    return;
                  }
                  return renderSelectedCountiesWithClose(item.id, item.title);
                })}
            </div>
            <div className='flex'>
              {(selectedCities.length !== 0 ||
                selectedDistrict.length !== 0 ||
                selectedCounty.length !== 0) &&
                renderDeletelocationSearch()}
            </div>

            <div className='pt-2 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8'>
              <div className='relative focus:outline-none focus-visible:ring-0 outline-none select-none'>
                <div
                  className={`${
                    !isCountySearch
                      ? 'px-5 py-2 bg-white rounded-full text-black dark:text-white'
                      : 'px-5 py-2'
                  }  `}
                  onClick={() => {
                    setIsCountySearch(false);
                  }}
                >
                  Kerületek
                </div>
              </div>
              <div className='relative focus:outline-none focus-visible:ring-0 outline-none select-none'>
                <div
                  className={`${
                    isCountySearch
                      ? 'px-5 py-2 bg-white rounded-full text-black dark:text-white'
                      : 'px-5 py-2'
                  }  `}
                  onClick={() => {
                    setIsCountySearch(true);
                  }}
                >
                  Megyék
                </div>
              </div>
            </div>

            <div>
              {!isCountySearch && (
                <div className='p-5 bg-white rounded-xl'>
                  <div className='relative flex flex-col space-y-5 mt-7'>
                    <div className='grid grid-cols-3 gap-4'>
                      {allDistrict?.map((item, index) => (
                        <div key={item.id} className=''>
                          {/* <Checkbox
                            name={item.title}
                            label={item.title}
                            subLabel={item.description}
                            key={item.id}
                            defaultChecked={selectedDistrict?.includes(
                              // @ts-ignore
                              allDistrict[index].id
                            )}
                            // defaultChecked={allDistrict[index].checked === true}
                            onChange={(e) => {
                              const newState = allDistrict.map((item, i) => {
                                if (i === index) {
                                  return { ...item, checked: e };
                                }
                                return item;
                              });

                              const onlyCheckedValues = newState.reduce(
                                // @ts-ignore
                                (previousValue, currentValue) => {
                                  //   console.log(currentValue.checked);
                                  if (currentValue.checked === true) {
                                    return [...previousValue, currentValue.id];
                                  }
                                  return previousValue;
                                },
                                []
                              );
                              setAllDistrict(newState);
                              setSelectedDistrict(onlyCheckedValues);
                            }}
                          /> */}
                          <Checkbox
                            name={item.title}
                            label={item.title}
                            value={selectedDistrict?.includes(
                              // @ts-ignore
                              allDistrict[index].id
                            )}
                            onChange={(e) => {
                              const newState = allDistrict.map((item, i) => {
                                if (i === index) {
                                  return { ...item, checked: e };
                                }
                                return item;
                              });

                              const onlyCheckedValues = newState.reduce(
                                // @ts-ignore
                                (previousValue, currentValue) => {
                                  //   console.log(currentValue.checked);
                                  if (currentValue.checked === true) {
                                    return [...previousValue, currentValue.id];
                                  }
                                  return previousValue;
                                },
                                []
                              );
                              setAllDistrict(newState);
                              //setSelectedDistrict(onlyCheckedValues);

                              onChangeSelectedDistrict(
                                newState,
                                onlyCheckedValues
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {console.log('==================================>>>>>>>>>>')}
              {/* {console.log(
                selectedDistrict?.includes(
                  // @ts-ignore
                  allDistrict[index].id
                )
              )} */}
              {console.log(selectedDistrict)}
              {allDistrict?.map((item, index) =>
                console.log(
                  selectedDistrict?.includes(
                    // @ts-ignore
                    allDistrict[index].id
                  )
                )
              )}
            </div>
            {isCountySearch && (
              // <MultipleSelect
              //   defaultValue={allCounty}
              //   sectionTitle={'Megyék választása'}
              //   onChange={(countyArray: any, selectedIDs) => {
              //     setAllCounty(countyArray);
              //     setSelectedCounty(selectedIDs);
              //   }}
              // />
              <div className='p-5 bg-white rounded-xl'>
                <div className='relative flex flex-col space-y-5 mt-7'>
                  <div className='grid grid-cols-3 gap-4'>
                    {allCounty?.map((item, index) => (
                      <div key={item.id}>
                        <Checkbox
                          name={item.title}
                          label={item.title}
                          value={selectedCounty?.includes(
                            // @ts-ignore
                            allCounty[index].id
                          )}
                          onChange={(e) => {
                            const newState = allCounty.map((item, i) => {
                              if (i === index) {
                                return { ...item, checked: e };
                              }
                              return item;
                            });

                            const onlyCheckedValues = newState.reduce(
                              // @ts-ignore
                              (previousValue, currentValue) => {
                                //   console.log(currentValue.checked);
                                if (currentValue.checked === true) {
                                  return [...previousValue, currentValue.id];
                                }
                                return previousValue;
                              },
                              []
                            );
                            setAllCounty(newState);
                            setSelectedCounty(onlyCheckedValues);

                            onChangeSelectedCounty(newState, onlyCheckedValues);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderSelectedLocationsWithClose = (
    locationID: number,
    locationName: string
  ) => {
    return (
      <div
        className={
          'flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all border-primary-500 bg-primary-50 text-primary-700 suggestionIdentifier'
        }
        onClick={() => {
          setSelectedCities((prevState) =>
            prevState.filter((prevItem) => prevItem.cityId !== locationID)
          );
        }}
      >
        <span className={'suggestionIdentifier'}>{locationName}</span>
        {renderXClear()}
      </div>
    );
  };

  const renderSelectedDistrictsWithClose = (
    districtID: number,
    districtName: string
  ) => {
    return (
      <div
        className={
          'flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all border-primary-500 bg-primary-50 text-primary-700 suggestionIdentifier'
        }
        onClick={() => {
          // setAllDistrict((prevState) =>
          //   prevState.filter((prevItem) => prevItem.id !== districtID)
          // );
          // setAllDistrict(
          //   allDistrict.map((district) =>
          //     district.id === districtID
          //       ? { ...district, checked: 'false' }
          //       : district
          //   )
          // );
          // setSelectedCities((prevSelectedCities) => [
          //   ...prevSelectedCities,
          //   { cityId: cityId, cityName: item },
          // ]);

          // setAllDistrict(
          //   allDistrict.map((district) =>
          //     district.id === districtID
          //       ? // ? { ...district, checked: false }
          //         { id: district.id, title: district.title }
          //       : district
          //   )
          // );

          setAllDistrict((prevState) =>
            prevState.map((district) =>
              district.id === districtID
                ? // ? { ...district, checked: false }
                  { id: district.id, title: district.title }
                : district
            )
          );

          // setSelectedDistrict(
          //   selectedDistrict.map((district) =>
          //     district.id === districtID
          //       ? // ? { ...district, checked: false }
          //         { id: district.id, title: district.title }
          //       : district
          //   )
          // );

          setSelectedDistrict((prevState) =>
            prevState.filter((prevItem) => prevItem !== districtID)
          );

          // setIsCountySearch(true);
          // setIsCountySearch(false);
          // switcherForStuckedState(false);

          // setAllDistrict((prevAllDistrict) => [
          //   ...prevAllDistrict,
          //   { id: 100, title: 'eh' },
          // ]);
          // setAllDistrict((prevState) =>
          //   prevState.filter((prevItem) => prevItem.id !== districtID)
          // );
          // const newState = [...allDistrict];
          // allDistrict.splice(districtID, 0, { id: 2, title: 'workin' });
          //setstate(newState);
          //setAllDistrict(newState);
          //triggerRerender(newState);
        }}
      >
        <span className={'suggestionIdentifier'}>{districtName}</span>
        {renderXClear()}
      </div>
    );
  };

  const renderSelectedCountiesWithClose = (
    countyID: number,
    countyName: string
  ) => {
    return (
      <div
        className={
          'flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all border-primary-500 bg-primary-50 text-primary-700 suggestionIdentifier'
        }
        onClick={() => {
          // setAllCounty((prevState) =>
          //   prevState.filter((prevItem) => prevItem.id !== countyID)
          // );
          setAllCounty((prevState) =>
            prevState.map((county) =>
              county.id === countyID
                ? // ? { ...county, checked: false }
                  { id: county.id, title: county.title, checked: false }
                : county
            )
          );

          setSelectedCounty((prevState) =>
            prevState.filter((prevItem) => prevItem !== countyID)
          );
        }}
      >
        <span className={'suggestionIdentifier'}>{countyName}</span>
        {renderXClear()}
      </div>
    );
  };

  const renderDeletelocationSearch = () => {
    return (
      <div
        className={
          'flex items-center justify-center text-red-500 hover:text-red-600 px-4 py-2 text-sm cursor-pointer font-bold suggestionIdentifier'
        }
        onClick={() => {
          setSelectedCities([]);
          setSelectedCounty([]);
          setSelectedDistrict([]);
          setAllDistrict((prevState) =>
            prevState.map((district) =>
              district.checked === true
                ? { id: district.id, title: district.title, cheked: false }
                : district
            )
          );
          setAllCounty((prevState) =>
            prevState.map((county) =>
              county.checked === true
                ? { id: county.id, title: county.title, checked: false }
                : county
            )
          );
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6 suggestionIdentifier'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
          />
        </svg>

        <span className='ml-2 suggestionIdentifier'>
          {'Választottak törlése'}
        </span>
      </div>
    );
  };

  const renderXClear = () => {
    return (
      <span className='w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer suggestionIdentifier'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-3 w-3 suggestionIdentifier'
          viewBox='0 0 20 20'
          fill='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
            clipRule='evenodd'
            className='suggestionIdentifier'
          />
        </svg>
      </span>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => {
          setShowPopover(true);
        }}
        className={`bg-red flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? 'nc-hero-field-focused' : ''
        }`}
      >
        <div className='text-neutral-300 dark:text-neutral-400'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='nc-icon-field'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
            />
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
            />
          </svg>
        </div>
        <div className='flex-grow'>
          {/* <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            // value={value}
            autoFocus={showPopover}
            // onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
            onChange={onChange}
            // onKeyDown={onKeyDown}
            value={input}
          /> */}
          <div className='block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate'>
            {'Település'}
          </div>
          <span className='block mt-0.5 text-sm text-neutral-400 font-light '>
            <span className='line-clamp-1'>{!!input ? placeHolder : desc}</span>
          </span>
          {input && showPopover && (
            <ClearDataButton
              onClick={() => {
                setInput('');
                setValue('');
              }}
            />
          )}
        </div>
      </div>
      {/* {console.log('shoppopower - input')}
      {console.log(showPopover, input)} */}
      {showPopover && (
        <div className='absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[850px] sm:min-h-[600px] bg-white dark:bg-neutral-800 top-full mt-3 rounded-3xl shadow-xl max-h-96 overflow-y-auto'>
          {/* {input !== '' ? renderSearchValue() : renderTopCities()} */}
          {renderSearchByLocation()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
