import React, { FC } from 'react';
import rightImgPng from 'images/our-features-2-d.png';
import NcImage from 'shared/NcImage/NcImage';
import Badge from 'shared/Badge/Badge';

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: 'type1' | 'type2';
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = 'lg:py-14',
  rightImg = rightImgPng,
  type = 'type1',
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === 'type1' ? 'lg:flex-row' : 'lg:flex-row-reverse'
      } ${className}`}
      data-nc-id='SectionOurFeatures'
    >
      <div className='flex-grow'>
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === 'type1' ? 'lg:pl-16' : 'lg:pr-16'
        }`}
      >
        <span className='uppercase text-sm text-gray-400 tracking-widest'>
          Előnyök
        </span>
        <h2 className='font-semibold text-4xl mt-5'>
          Miért válasszon bennünket ?
        </h2>

        <ul className='space-y-10 mt-16'>
          <li className='space-y-4'>
            <Badge name='Új szemlélet' />
            <span className='block text-xl font-semibold'>
              Innovatív ingatlan-közvetítő rendszer
            </span>
            <span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
              Innovatív technikával és rendkívüli rugalmassággal közelítjük meg
              ezt a piaci szegmenst.
            </span>
          </li>
          <li className='space-y-4'>
            <Badge color='green' name='Részletek ' />
            <span className='block text-xl font-semibold'>
              {/*Az ördög és a jó ingatlan is, a részletekben rejlik*/}A
              tökéletesség a részletekben rejlik.
            </span>
            <span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
              Tapasztalt és képzett munkatársaink várják megbízásaikat
            </span>
          </li>
          <li className='space-y-4'>
            <Badge color='red' name='Premium Service' />
            <span className='block text-xl font-semibold'>
              Prémium kiszolgálás
            </span>
            <span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
              Bátran fordulhat hozzánk energetikai tanúsítványért, művészi
              színvonalú lakásfényképezésért, kérheti speciálisan ingatlanjogra
              szakosodott ügyvédi segítségét – a MULTIHOME-Ingatlan teljes körű
              támogató segítséget nyújt az értékesítéshez.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
