import React, { FC, useEffect } from 'react';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import SectionGridHasMap from './SectionGridHasMap';
import { Helmet } from 'react-helmet';
import SectionHero2ArchivePage from 'components/SectionHero2ArchivePage/SectionHero2ArchivePage';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { PropertiesContextProvider } from 'context/PropertiesContext';
import { PropertiesContext } from 'context/PropertiesContext';
import { getAllProperties } from 'data/DataController';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';
import HIW1img from 'images/HIW2-1.png';
import HIW2img from 'images/HIW2-2.png';
import HIW3img from 'images/HIW2-3.png';
import HIW1imgDark from 'images/HIW2-1-dark.png';
import HIW2imgDark from 'images/HIW2-2-dark.png';
import HIW3imgDark from 'images/HIW2-3-dark.png';
import SectionOurFeatures from 'components/SectionOurFeatures/SectionOurFeatures';
//import SectionOurFeatures from '../../containers/PageHome/SectionGridFeatureProperty';
import SectionDowloadApp from '../../containers/PageHome/SectionDowloadApp';
import rightImgPng from 'images/our-features-2-fixed.png';
import SectionGridFilterCard from './SectionGridFilterCard';
import SectionGridFeatureProperty from 'containers/PageHome/SectionGridFeatureProperty';
import HeroSearchMobile from 'components/HeroSearchMobile/HeroSearchMobile';

export interface ListingRealEstateMapPageProps {
  className?: string;
}

const ListingRealEstateMapPage: FC<ListingRealEstateMapPageProps> = ({
  className = '',
}) => {
  useEffect(() => {
    const $body = document.querySelector('body');
    if ($body) {
      $body.className = 'theme-multihome-green';
    }
    return () => {
      if ($body) {
        $body.className = '';
      }
    };
  }, []);

  return (
    <div
      className={`nc-ListingRealEstateMapPage relative ${className}`}
      data-nc-id='ListingRealEstateMapPage'
    >
      <Helmet>
        <title>Multihome Ingatlanok</title>
      </Helmet>

      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* <div className='px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center'>
        <div className='lg:hidden flex-[3] max-w-lg !mx-auto md:px-3'>
          <HeroSearchMobile />
        </div>
      </div> */}

      <PropertiesContextProvider>
        {/* SECTION HERO */}
        <div className='container pb-0 lg:pb-32'>
          <SectionHero2ArchivePage className='lg:mt-2' />
        </div>

        {/* SECTION CARDS AND SIDE MAP*/}
        <div className='container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none'>
          <SectionGridHasMap />
        </div>
        <div className='container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32'>
          <SectionHowItWork
            data={[
              {
                id: 1,
                img: HIW1img,
                imgDark: HIW1imgDark,
                title: 'Részletes keresés',
                desc: 'Szűrj számos feltétel alapján, Legyen az akár a parkolás típusa vagy bármi ami számodra fontos. ',
              },
              {
                id: 2,
                img: HIW2img,
                imgDark: HIW2imgDark,
                title: 'A vágyott ingatlan felfedezése',
                desc: 'Találd meg a számodra tökéletes ingatlant. Ha most nem találsz ilyet regsiztrálj és kérj értesítést amint lesz a feltételeidnek megfelelő ingatlan.',
              },
              {
                id: 3,
                img: HIW3img,
                imgDark: HIW3imgDark,
                title: 'Egyeztetés, ingatlan megvásárlása',
                desc: 'Vedd fel angol, német vagy magyar anyanyelven beszélő kollégánkkal a kapcsolatot és juss hozzá a vágyott ingatlanhoz.',
              },
            ]}
          />

          {/* <SectionGridFilterCard className='' /> */}

          {/* <SectionGridFeatureProperty /> */}
          <div className='relative py-16'>
            <BackgroundSection />
            <SectionGridFeatureProperty />
          </div>

          {/* SECTION2 */}
          {/* @ts-ignore
          <SectionOurFeatures type='type2' rightImg={rightImgPng} /> */}

          {/* SECTION2 */}
          <SectionOurFeatures type='type2' rightImg={rightImgPng} />

          {/* SECTION */}
          <SectionDowloadApp />
        </div>
      </PropertiesContextProvider>
    </div>
  );
};

export default ListingRealEstateMapPage;
