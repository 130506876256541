import React, { FC, useEffect } from 'react';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import StartRating from 'components/StartRating/StartRating';
import { Link } from 'react-router-dom';
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon';
import BtnMarkerIcon from 'components/BtnMarkerIcon/BtnMarkerIcon';
import SaleOffBadge from 'components/SaleOffBadge/SaleOffBadge';
import Badge from 'shared/Badge/Badge';
import { StayDataType } from 'data/types';
import NcImage from 'shared/NcImage/NcImage';
import appConfig from 'config/appConfig';

export interface PropertyCardHProps {
  className?: string;
  props?: any;
  data?: any;
  onGoToLocation?: any;
  showMarker?: boolean;
  showCity?: boolean;
  gridColNumber?: number;
  gapNumber?: number;
  gapNumberMobileArray?: Array<number>;
}

const PropertyCardH: FC<PropertyCardHProps> = ({
  className = '',
  data,
  onGoToLocation,
  showMarker,
  showCity,
  gridColNumber = 3,
  gapNumber = 6,
  gapNumberMobileArray = [6, 2],
}) => {
  const {
    address,
    baths,
    beds,
    half_beds,
    city,
    discount,
    garages,
    image,
    latitude,
    longitude,
    oldprice,
    price,
    price_m2,
    rating,
    size,
    plot_size,
    status,
    statusId,
    title,
    type,
    id,
  } = data;

  // console.log('All property card data: ');
  // console.log(data);

  // console.log('Data: ');
  // console.log(data);

  // useEffect(() => {
  //   var numb = oldprice.match(/\d/g).join('');
  //   // numb = numb.join('');
  //   alert(numb);
  // }, []);

  let priceSalePercent: string;

  if (oldprice) {
    const priceoldNum = oldprice.match(/\d/g).join('');
    const priceNum = price.match(/\d/g).join('');
    //console.log(Math.round(((priceoldNum - priceNum) / priceoldNum) * 100));

    // priceSalePercent =
    //   '- ' +
    //   Math.round(((priceoldNum - priceNum) / priceoldNum) * 100) +
    //   '%' +
    //   ' price drop';
    priceSalePercent =
      Math.round(((priceoldNum - priceNum) / priceoldNum) * 100) +
      '%' +
      ' árzuhanás';
  }

  //const propertyLink = `${appConfig.URL}property/${id}`;
  const propertyLink = `/property/${id}`;
  //const propertyLink = `/property`;
  //const propertyLink = `https://vevoszerzo.whitewebdesign.hu/property`;

  const containsNumber = /\d/;

  const renderSliderGallery = () => {
    return (
      <div className='flex-shrink-0 p-3 w-full sm:w-64 '>
        {/* <GallerySlider
          ratioClass='aspect-w-1 aspect-h-1'
          galleryImgs={image}
          className='w-full h-full rounded-2xl overflow-hidden will-change-transform'
          uniqueID={`PropertyCardH_${id}`}
        /> */}
        <div className='aspect-w-1 aspect-h-1'>
          <NcImage
            src={image}
            className='object-cover w-full h-full rounded-2xl'
          />
        </div>
        {oldprice && priceSalePercent && (
          <SaleOffBadge
            className='absolute left-5 top-5 '
            desc={priceSalePercent}
          />
        )}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div
        className={`inline-grid grid-cols-2 sm:grid-cols-${gridColNumber} gap-x-${gapNumberMobileArray[0]} gap-y-${gapNumberMobileArray[1]} sm:gap-${gapNumber}`}
      >
        {beds != 0 && (
          <div className='flex items-center space-x-2'>
            <span className='sm:inline-block'>
              <i className='las la-bed text-lg'></i>
            </span>
            <span className='text-xs text-neutral-500 dark:text-neutral-400'>
              {beds + ' '}
              {half_beds && ' + ' + half_beds + ' fél'}
              {'szoba'}
            </span>
          </div>
        )}

        {/* {baths != 0 && (
          <div className='flex items-center space-x-2'>
            <span className=' sm:inline-block'>
              <i className='las la-bath text-lg'></i>
            </span>
            <span className='text-xs text-neutral-500 dark:text-neutral-400'>
              {baths + ' fürdőszoba'}
            </span>
          </div>
        )} */}

        {statusId === 2 && baths != 0 && (
          <div className='flex items-center space-x-2'>
            <span className=' sm:inline-block'>
              <i className='las la-bath text-lg'></i>
            </span>
            <span className='text-xs text-neutral-500 dark:text-neutral-400'>
              {baths + ' fürdőszoba'}
            </span>
          </div>
        )}

        {statusId === 1 && price_m2 && (
          <div className='flex items-center space-x-2'>
            <span className=' sm:inline-block'>
              <i className='las la-tag text-lg'></i>
            </span>
            <span className='text-xs text-neutral-500 dark:text-neutral-400'>
              {price_m2 + ' /m²'}
            </span>
          </div>
        )}

        {containsNumber.test(size) && size != 0 && (
          <div className='flex items-center space-x-2'>
            <span className=' sm:inline-block'>
              <i className='las la-expand-arrows-alt text-lg'></i>
            </span>
            <span className='text-xs text-neutral-500 dark:text-neutral-400'>
              {size}
            </span>
          </div>
        )}

        {containsNumber.test(plot_size) && plot_size !== '0 m²' && (
          <div className='flex items-center space-x-2'>
            <span className=' sm:inline-block'>
              <i className='las la-expand text-lg'></i>
            </span>
            <span className='text-xs text-neutral-500 dark:text-neutral-400'>
              {plot_size}
            </span>
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className='flex-grow p-3 sm:pr-6 flex flex-col items-start'>
        <div className='space-y-4 w-full'>
          <div className='inline-flex space-x-3'>
            <Badge
              name={
                <div className='flex items-center'>
                  <i className='text-sm las la-tag'></i>
                  <span className='ml-1'>{status}</span>
                </div>
              }
              color='dark-green'
            />
            <Badge
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1 truncate'>{type}</span>
                </div>
              }
              color='green'
            />
            {showCity && (
              <Badge
                name={
                  <div className='flex items-center'>
                    <i className='text-sm las la-map-marker-alt'></i>
                    {/* <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-5 w-5'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={1.5}
                        d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={1.5}
                        d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                      />
                    </svg> */}
                    <span className='ml-1'>{city}</span>
                  </div>
                }
                color='light-green'
              />
            )}
          </div>
          <div className='flex items-center space-x-2'>
            {/* {isAds && <Badge name='ADS' color='green' />} */}
            <h2 className='text-lg font-medium capitalize'>
              <span className='line-clamp-2'>{title}</span>
              {/* <span className='line-clamp-2'>{title + ' - ' + type}</span> */}
            </h2>
          </div>
          {renderTienIch()}
          <div className='w-14 border-b border-neutral-100 dark:border-neutral-800 '></div>
          <div className='flex w-full justify-between items-end'>
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
            <span className='flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500'>
              {/* TODO: ha for rent akkor / month-ot éár mögé írni -> type nak a id-je kéne nem 'for rent' stringel matcehlni, multialng lesz */}
              {statusId === 2 ? `${price} /hó` : `${price}`}
            </span>
            {/* <div className='flex items-center space-x-2'>
              <span className=' sm:inline-block'>
                <i className='las la-tag text-lg'></i>
              </span>
              <span className='text-xs text-neutral-500 dark:text-neutral-400'>
                {price_m2 + ' /m²'}
              </span>
            </div> */}
            {/* {statusId === 1 && (
              <span className='flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500'>
                {price_m2 + ' /m²'}
              </span>
            )} */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id='PropertyCardH'
    >
      {/* <Link
        to={propertyLink}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className='h-full w-full flex flex-col sm:flex-row sm:items-center'
      > */}
      <a
        href={propertyLink}
        target='_blank'
        className='h-full w-full flex flex-col sm:flex-row sm:items-center'
      >
        {renderSliderGallery()}
        {renderContent()}
      </a>
      {/* </Link> */}

      {showMarker && (
        <BtnMarkerIcon
          className='absolute right-5 top-5 sm:right-3 sm:top-3'
          onPress={() => {
            // console.log('click' + longitude + ' - ' + latitude);
            onGoToLocation(latitude, longitude);
          }}
        />
      )}
    </div>
  );
};

export default PropertyCardH;
